<template>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="回访页面"
                 left-text="返回"
                 @click-left="router.back"/>
    <div class="page-container">
      <van-form @submit="onSubmit" ref="formRef">
        <van-cell-group inset>
          <van-field label="回访内容"
                     placeholder="请填写回访内容"
                     v-model.trim="form.feedback"
                     autosize
                     clearable
                     show-word-limit
                     type="textarea"
                     maxlength="128"
                     name="feedback"/>
          <van-field name="score" label="评分" required :rules="[{ required: true, message: '请选择评分' }]">
            <template #input>
              <van-rate v-model="form.score" count="3"/>
            </template>
          </van-field>

          <van-row>
            <van-col span="16">
              <van-field label="验证码"
                         placeholder="请输入验证码"
                         name="captcha"
                         required
                         clearable
                         v-model.trim="form.captcha"
                         :rules="[{ validator: validationCaptcha, message: '请输入正确内容' }]"/>
            </van-col>
            <van-col span="8">
              <van-image width="108" height="40" :src="captchaUrl" @click="requestCaptcha"/>
            </van-col>
          </van-row>

          <van-cell>
            <van-button round block type="primary" native-type="submit" class="submit">提交</van-button>
          </van-cell>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {Toast} from "vant";
import {queryFeedback, queryFeedbackCaptcha} from "@/api/workorder";

const router = useRouter();

const form = reactive({
  feedback: "",
  score: null,
  captcha: ""
});

const formRef = ref(null);
const errorMessage = ref("");
const captchaUrl = ref("");
const captchaId = ref("");

const query = getUrlParam();

/**
 * 校验验证码
 * @param value 验证码
 */
const validationCaptcha = value => {
  if (!!value) {
    if (!!errorMessage.value) {
      return errorMessage.value;
    } else {
      return true;
    }
  } else {
    return "请输入验证码";
  }
};

const requestCaptcha = () => {
  queryFeedbackCaptcha().then(res => {
    captchaUrl.value = res.data.base64Image;
    captchaId.value = res.data.captchaId;
    errorMessage.value = "";
  }).catch(err => {
    console.error("Request captcha failed", err);
  });
};

const onSubmit = (data) => {
  data.workorderId = query.workorderId;
  data.captchaId = captchaId.value;
  console.log(data);
  queryFeedback(data).then(res => {
    if (res.status === 20000) {
      Toast({
        message: "回访完成！",
        forbidClick: true
      });
      router.back();
    }
  });
};

onMounted(() => {
  validationUrlParam();
  if (!query.workorderId) {
    router.back();
    Toast.fail({
      message: "暂无查询到工单ID",
      forbidClick: true
    });
  }
  requestCaptcha();
});
</script>

<style scoped>

</style>
